.PageNavigation {
  font-size: 14px;
  display: block;
  width: auto;
  overflow: hidden;
}

.PageNavigation a {
  display: block;
  float: left;
  margin: 1em 0;
}

@media only screen and (min-width: 770px) {
  .PageNavigation a {
    width: 50%;  }
}

.PageNavigation .prev {
  text-align: left;
}

.PageNavigation .next {
  text-align: right;
}

video {
  object-fit: contain;
  width: 100%;
  height: 100%;
}